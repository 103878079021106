import React from "react"
import { connect } from "react-redux"
import { Control, Form as ReduxForm, actions } from "react-redux-form"
import { Form, Col, Button } from "react-bootstrap"
import "./css/styles.css"

import ENVIRONMENT from "../config/environment"
import Layout from "../components/layout"
import Loading from "../components/loading"
import SEO from "../components/seo"
import ErrorApi from "../components/error"
import SuccessApi from "../components/success"
import { ErrorList, validateEmail } from "../components/validation"

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      errorMessage: null,
      requestInProgress: false,
      pageInitialized: false,
      successful: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({pageInitialized: true})
    }, 5000)
  }

  render() {
    const { error, errorMessage, requestInProgress, successful } = this.state
    return (
        <Layout>
          <SEO title="Contact us" />
          <h1>Contact us</h1>

          {error && (
              <ErrorApi>
                <p>{errorMessage}</p>
              </ErrorApi>
          )}

          {successful && <SuccessApi>Your message have been sent</SuccessApi>}
          <p>
            For any question regarding getting Khiops, installing Khiops or using
            Khiops, just send us a message via the form below.
          </p>
          <ReduxForm
              model="contactForm.data"
              onSubmit={values => this.handleSubmit(values)}
              onChange={values => this.handleChange(values)}
          >
            <Form.Row>
              <Col md={10} lg={8} xl={6}>
                <Form.Group>
                  <Form.Label htmlFor="email" className="is-required">
                    Email
                  </Form.Label>
                  <Control.text
                      className="form-control"
                      model=".email"
                      id="email"
                      defaultValue={this.props.user.email || ""}
                      readOnly={this.props.user.email}
                      validators={{
                        required: val => val && val.length,
                        isEmail: email => validateEmail(email || "")
                      }}
                  />
                  <ErrorList
                      model=".email"
                      messages={{
                        isEmail:
                            "A valid email address is required (eg: firstname.lastname@orange.com)"
                      }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="firstName" className="is-required">
                    First name
                  </Form.Label>
                  <Control.text
                      className="form-control"
                      model=".firstName"
                      id="firstName"
                      defaultValue={this.props.user.firstName || ""}
                      readOnly={this.props.user.firstName}
                      validators={{
                        required: val => val && val.length
                      }}
                  />
                  <ErrorList
                      model=".firstName"
                      messages={{
                        required: "First name is required"
                      }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="lastName" className="is-required">
                    Last name
                  </Form.Label>
                  <Control.text
                      className="form-control"
                      model=".lastName"
                      id="lastName"
                      defaultValue={this.props.user.lastName || ""}
                      readOnly={this.props.user.lastName}
                      validators={{
                        required: val => val && val.length
                      }}
                  />
                  <ErrorList
                      model=".lastName"
                      messages={{
                        required: "Last name is required"
                      }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="question" className="is-required">
                    Question
                  </Form.Label>
                  <Control.text
                      className="form-control"
                      model=".question"
                      id="question"
                      validators={{
                        required: val => val && val.length
                      }}
                  />
                  <ErrorList
                      model=".question"
                      messages={{
                        required: "Question is required"
                      }}
                  />
                </Form.Group>
                <Form.Group  className="filter">
                  <Form.Label htmlFor="filter">
                    Filter
                  </Form.Label>
                  <Control.text
                      className="form-control"
                      model=".filter"
                      id="filter"
                      autoComplete="off"
                      tabIndex="-1"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="message" className="is-required">
                    Message
                  </Form.Label>
                  <Control.textarea
                      className="form-control"
                      model=".message"
                      id="message"
                      rows={6}
                      validators={{
                        required: val => val && val.length,
                        sizeLimit: val => !val || !val.length || val.length <= 20000
                      }}
                  />
                  <ErrorList
                      model=".message"
                      messages={{
                        required: "Message is required",
                        sizeLimit:
                            "Your message can contain 20,000 characters at most"
                      }}
                  />
                </Form.Group>
                {!requestInProgress ? (
                    <Button type="submit" data-test="submit">
                      Submit message
                    </Button>
                ) : (
                    <Loading />
                )}
              </Col>
            </Form.Row>
          </ReduxForm>
        </Layout>
    )
  }

  handleChange() {
    this.setState({
      successful: false
    })
  }

  async handleSubmit(values) {
    const {pageInitialized} = this.state;
    if (ENVIRONMENT.name === "ssr") {
      return // no submit in SSR
    }

    this.setState({
      requestInProgress: true,
      successful: false
    })

    const body = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      question: values.question,
      message: values.message,
      filter: values.filter
    }

    if (body.filter !== "" || pageInitialized === false) {
      return;
    }
    else {

      const response = await fetch("/api/contact/request", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
      if (response.ok) {
        this.setState(
            { error: false, requestInProgress: false, errorMessage: null },
            async () => {
              const result = await response.json()
              if (result.status === "ok") {
                this.setState({ error: false, successful: true })
                /*
                this.props.onBoarded({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  usageType: values.usageType,
                  interests,
                }); */
                // navigate('/my-licenses');
              } else {
                this.setState({ error: true, errorMessage: null })
              }
            }
        )
      } else {
        this.setState({ error: true, requestInProgress: false })
      }
    }
  }

  componentWillUnmount() {
    this.props.resetModel("contactForm.data")
  }
}

export default connect(
    ({ user }) => ({ user }),
    dispatch => ({
      resetModel: model => dispatch(actions.reset(model))
    })
)(Contact)
